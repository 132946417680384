














import { Component, Vue } from 'vue-property-decorator'
import { readIsLoggedIn } from '@/store/main/getters'
import { dispatchCheckLoggedIn } from '@/store/main/actions'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { getQueryVariable } from './utils/util'
@Component({
  computed: {
    antLocale: {
      get() {
        return this.$store.state.main.antLocale || zhCN
      },
    },
  },
})
export default class App extends Vue {
  get loggedIn() {
    return readIsLoggedIn(this.$store)
  }

  public async created() {
    let fromFlag: any = getQueryVariable('from')
    if (!fromFlag) {
      fromFlag = localStorage.getItem('fromFlag')
    }
    if (fromFlag) {
      localStorage.setItem('fromFlag', fromFlag)
      this.$store.commit('setFromFlag', fromFlag)
    }
    await dispatchCheckLoggedIn(this.$store)
    if (localStorage.getItem('showOrgCode')) {
      this.$store.commit('showOrgCode', localStorage.getItem('showOrgCode'))
    }
  }
}

import { IUserProfile } from '@/interfaces'
import { getStoreAccessors } from 'typesafe-vuex'
import { State } from '../state'
import { AppNotification, MainState } from './state'


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload
    },
    setFromFlag(state: MainState, payload: string) {
        state.fromFlag = payload
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload
    },
    setDashboardMiniDrawer(state: MainState, payload: boolean) {
        state.dashboardMiniDrawer = payload
    },
    setDashboardShowDrawer(state: MainState, payload: boolean) {
        state.dashboardShowDrawer = payload
    },
    addNotification(state: MainState, payload: AppNotification) {
        state.notifications.push(payload)
    },
    removeNotification(state: MainState, payload: AppNotification) {
        state.notifications = state.notifications.filter((notification) => notification !== payload)
    },
    setEnterpriseData(state: MainState, payload: any) {
        state.enterprise.enterpriseData = payload
    },
    setEnterpriseHashData(state: MainState, payload: any) {
        state.enterprise.enterpriseHashData = payload
    },
    setSelectedOrg(state: MainState, payload: any) {
        state.enterprise.selectedOrg = payload
    },
    setSelectedKeys(state: MainState, payload: any) {
        state.enterprise.selectedKeys = payload
    },
    setExpandedKeys(state: MainState, payload: any) {
        state.enterprise.expandedKeys = payload
    },
    setRanterId(state: MainState, payload: any) {
        state.pagePublic.ranterId = payload
    },
    setRanterName(state: MainState, payload: any) {
        state.pagePublic.ranterName = payload
    },
    setRanterAvatarUrl(state: MainState, payload: any) {
      state.pagePublic.ranterAvatarUrl = payload
    },
    setUserData(state: MainState, payload: any) {
        state.userAuthorityShow.userData = payload
    },
    setHadEditFlag(state: MainState, payload: any) {
        state.userAuthorityShow.hadEditFlag = payload
    },
    setDeviceData(state: MainState, payload: any) {
        state.deviceHardWare.deviceData = payload
    },
    setDeviceBlicklist(state: MainState, payload: any) {
        state.deviceHardWare.blacklist = payload
    },
    setLoading(state: MainState, payload: any) {
        state.waitloading = payload
    },
    setIsNoJumping(state: MainState, payload: any) {
        state.pagePublic.isNoJumping = payload
    },
    setLastActionType(state: MainState, payload: any) {
        state.pagePublic.lastActionType = payload
    },
    setVuexRest(state: MainState) {
        // state.enterprise.enterpriseData = []
        // state.enterprise.enterpriseHashData = []
        state.enterprise.selectedOrg = {}
        state.enterprise.selectedKeys = []
        state.enterprise.expandedKeys = []
        state.pagePublic.ranterId = -10
        state.pagePublic.ranterName = ''
        state.userAuthorityShow.userData = [{}]
        state.deviceHardWare.deviceData = [{}]
        state.deviceHardWare.blacklist = [{}]
    },
    setOrgChangeData(state: MainState, payload: any) {
      state.publicStatus.orgChangeData = payload
    },
    setEnterpriseFeatureData(state: MainState, payload: any) {
      state.enterprise.featureData = payload
    },
    showOrgCode(state: MainState, payload: any) {
      state.enterprise.showOrgCode = payload
    },
}

const {commit} = getStoreAccessors<MainState | any, State>('')

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer)
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer)
export const commitSetLoggedIn = commit(mutations.setLoggedIn)
export const commitSetLogInError = commit(mutations.setLogInError)
export const commitSetToken = commit(mutations.setToken)
export const commitSetUserProfile = commit(mutations.setUserProfile)
export const commitAddNotification = commit(mutations.addNotification)
export const commitRemoveNotification = commit(mutations.removeNotification)
export const commitSetEnterpriseHashData = commit(mutations.setEnterpriseHashData)
export const commitSetLoading = commit(mutations.setLoading)
export const commitSetVuexRest = commit(mutations.setVuexRest)
export const commitSetHadEditFlag = commit(mutations.setHadEditFlag)
export const commitSetOrgChangeData = commit(mutations.setOrgChangeData)

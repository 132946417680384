import '@babel/polyfill'
// Import Component hooks before component definitions
import './component-hooks'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import './registerServiceWorker'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import '@/components/MainTheme.less'
import VueI18n from 'vue-i18n'
import VChart from 'v-charts'
import VeeValidate from 'vee-validate'
// 默认隐形any无法使用自定义模块已改为require
// import LangENUS from './common/lang/en-us'
// import LangZHCN from './common/lang/zh-cn'
// import LangJAJP from './common/lang/ja-jp'

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VChart)
Vue.use(VueI18n)
Vue.use(VeeValidate)
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'zh-cn',
  messages: {
    'en-us': require('./locales/lang/en-us.json'),
    'zh-cn': require('./locales/lang/zh-cn.json'),
    'ja-jp': require('./locales/lang/ja-jp.json'),
  },
})

window['vm'] = new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app')

const env = process.env.VUE_APP_ENV

let envApiUrl = ''

if (env === 'development') {
  envApiUrl = 'http://localhost'
} else if (process.env.VUE_APP_DOMAIN) {
  envApiUrl = `${process.env.VUE_APP_DOMAIN}`
}

export const apiUrl = envApiUrl
export const appName = process.env.VUE_APP_NAME

import _ from 'lodash'
import moment from 'moment'
/**
 * 根据用数字型的用户类型，获到格式化后的用户类型
 * userType:平台管理员 0 -> superuser| 机构管理员 1 -> manager | 机构操作员 2 -> operator
 * @param type
 * @returns
 */
export const getGlobalUserType = (type: number) => {

  switch (type) {
    case 0:
    return 'superuser'
    case 1:
    return 'manager'
    case 2:
    return 'operator'

    default:
      return 'operator'
  }
}

/**
 * 从 localStorage 中获取用户类型
 * @returns
 */
export const getStorageUserType = () => {
  return localStorage.getItem('userType')
}

/**
 * 获取当前时间 YYYY-MM-DD HH:mm:ss 形式
 * @returns
 */
export const getNow = (format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment().format(format)
}

/**
 * 根据传入的时间跨度，获取对应的时间 YYYY-MM-DD HH:mm:ss
 * @param  {moment.unitOfTime.StartOf} dateRange
 * @returns
 */
export const getDateByDateRange = (dateRange: moment.unitOfTime.StartOf, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment().startOf(dateRange).format(format)
}

/**
 * 构建树状结构hashMap
 * @param {any[]} tree
 * @param {any} hash
 */
export const createHash = (tree, hash) => {
  if (_.isEmpty(tree)) {
    return {}
  }

  _.forEach(tree, (item: any, index: number) => {
    let maxSort = 0

    _.forEach(tree, ( iteratee ) => {
      if (iteratee.sort > maxSort) {
        maxSort = iteratee.sort
      }
    })

    if (_.has(item, 'children')) {
      // 前一个节点 id
      if (index - 1 >= 0) {
        item.prev_id = tree[index - 1].id
      }
      // 后一个节点 id
      if (index + 1 < tree.length) {
        item.next_id = tree[index + 1].id
      }

      item.max_sort = maxSort
      hash[item.id] = item
      createHash(item.children, hash)
    } else {
      // 前一个节点 id
      if (index - 1 >= 0) {
        item.prev_id = tree[index - 1].id
      }
      // 后一个节点 id
      if (index + 1 < tree.length) {
        item.next_id = tree[index + 1].id
      }
      item.max_sort = maxSort
      hash[item.id] = item
    }
  })

  return hash
}

/**
 * 将树形数据平铺为同级数组
 * @param {array} treeList -- 要平铺的list数组
 * @param {string} childListKey -- 树节点的子集list的id的名称
 */
export const flatten = (treeList: any[] = [], childListKey: string = 'children') => {
  const data: any[] = []
  const func = (tree, childKey) => {
    tree.forEach( (item) => {
      data.push(item)
      if (item[childKey] && item[childKey].length !== 0) {
          // 说明有子节点
          func(item[childKey], childKey = 'children')
      }
    })
  }
  func(treeList, childListKey)
  return data
}

/**
 * 树形结构广度优先遍历
 * @param {any[]} tree -- 要遍历的tree
 * @param {func} callback -- 回调函数
 */
export const breadthSearch = (tree: any[], callback: (item: any) => void) => {
  let stark: any[] = []

  stark = stark.concat(tree)

  while (stark.length) {
    const temp: any = stark.shift()
    if (temp.children) {
      stark = stark.concat(temp.children)
    }
    callback(temp)
  }
}

/**
 * Promise 请求包装
 * @param promise
 * @returns
 */
export const to = (promise: any) => {
  return promise.then((data) => {
      return [null, data]
  }).catch((err) => [err])
}

/** 获取请求错误状态码
 * @param {Error} err
 * @returns
 */
export const getRequestErrCode = (err): number => {
  return err.response.status
}

/**
 * 获取请求错误的内容
 * @param {Error} err
 * @returns
 */
export const getRequestErrContent = (err): string => {
  return err.response.data.detail
}

/**
 * 根据传入的id在hashmap里找到所有的子节点id
 * @param {any[]} enterpriseHashData 组织 Hashmap
 * @param {number} rootId 根节点id
 * @param {boolean} isContainRootId 是否包含根节点（默认包含 true）
 * @returns {number[]} 取得的组织id集合
 */
export const getAllSubOrgIds = (
  enterpriseHashData: any[],
  rootId: number,
  isContainRootId: boolean = true): number[] => {
    const flattened = flatten(enterpriseHashData[rootId].children)
    const fattenedIds = _.map(flattened, (item) => {
      return item.id
    })

    if (isContainRootId) {
      return [rootId, ...fattenedIds]
    }

    return fattenedIds
}

/**
 * 该节点是否存在子节的
 * @param tree 树
 * @returns
 */
export const isHasChildren = (node) => {
  let flag = false
  if (node.children && node.children.length > 0) {
    flag = true
  }
  return flag
}

/**
 * 获取树的深度
 * @param tree
 * @returns
 */
export const getTreeDepth = (node) => {
  if (!node) { return 0 }

  let deepth = 0
  // 树中当前层节点的集合。
  let currentLevelNodes = [node]
  // 判断当前层是否有节点
  while (currentLevelNodes.length > 0) {
    // 当前层有节点，深度可以加一。
    deepth++
    // 下一层节点的集合。
    let nextLevelNodes = new Array()
    // 找到树中所有的下一层节点，并把这些节点放到 nextLevelNodes 中。
    for (const item of currentLevelNodes) {
      if (isHasChildren(item)) {
          nextLevelNodes = nextLevelNodes.concat(item.children)
      }
    }
    // 令当前层节点集合的引用指向下一层节点的集合。
    currentLevelNodes = nextLevelNodes
  }
  return deepth
}

/**
 * 从 当前链接中获取参数 中获取用户类型
 * @param variable
 * @returns
 */
export const getQueryVariable = (variable: any) => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (const p of vars) {
    const pair = p.split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return(false)
}

import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { MainState } from './state'
const defaultState: MainState = {
  isLoggedIn: null,
  token: '',
  fromFlag: '',
  logInError: false,
  userProfile: {
    email: 'email',
    full_name: '',
    id: 0,
    user_type: 5,
    activated: true,
    first_name: '',
    last_name: '',
    orgs: [],
    login: ''
  },
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  antLocale: {},
  navChoce: 'home',
  publicAuthority: '',
  source: {
    token: null,
    cancel: null,
  },
  userAuthorityShow: {
    userData: [{}],
    total_page: 1,
    page: 1,
    limit: 0,
    hadEditFlag : false
  },
  deviceHardWare: {
    deviceData: [{ id: 1, name: 'abc' }],
    blacklist: [{id: 1, name: 'abc'}],
    total_page: 1,
    page: 1,
    limit: 0,
  },
  enterprise: {
    enterpriseData: [],
    enterpriseHashData: [],
    selectedOrg: {},
    selectedKeys: [],
    expandedKeys: [],
    returnEData: [],
    page: 1,
    limit: 0,
    featureData: {},
    showOrgCode: false,
  },
  headTagsave: [], // 头部存储已经打开过的页面
  messagePro: {}, // 页面传值对象
  pagePublic: {
    ranterId: -10, // 点击的租户的ID
    ranterName: '', // 点击的租户名
    ranterAvatarUrl: '', // 租户的头像
    isNoJumping: false,
    lastActionType: [''] // 处于编辑状态时最后一次点击事件进行的操作类型
  },
  screen: {
    width: document.documentElement.clientWidth, // 屏幕宽度
    height: document.documentElement.clientHeight, // 屏幕高度
  },
  waitloading: false,
  publicStatus: {
    orgChangeData: 0
  }
}

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
}

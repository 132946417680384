import Vue from 'vue'
import Router, { RawLocation } from 'vue-router'

import RouterComponent from './components/RouterComponent.vue'

const originalPush = Router.prototype.push as any
Router.prototype.push = function push(this: any, location : RawLocation) {
  if (onreset) { return originalPush.call(this, location) }
  return (originalPush.call(this, location) as any).catch((err: any) => err)
}

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'dashboard',
          component: () =>
            import(/* webpackChunkName: "main-dashboard" */ './views/main/other/HomePageShow.vue'),
        },
        {
          path: 'home-header',
          component: () => import(/* webpackChunkName: "main" */ './views/main/HomeHeader.vue'),
          children: [
            {
              path: 'home',
              component: () =>
                import(
                  /* webpackChunkName: "main-dashboard" */ './views/main/other/HomePageShow.vue'
                ),
            },
            {
              path: 'home-blank',
              component: () =>
                import(
                  /* webpackChunkName: "main-dashboard" */ './views/main/other/HomeBlank.vue'
                ),
            }
          ],
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'guider-nav-page',
              component: RouterComponent,
              redirect: 'guider-nav-page/tables',
              children: [
                {
                  path: 'tables',
                  name: 'tables',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/bottom_guild/GuilderNavPage.vue'
                    ),
                  children: [
                    {
                      path: 'person',
                      name: 'person',
                      component: () =>
                        import(
                          /* webpackChunkName: "main-profile" */ './views/main/user/UserAuthorityShow.vue'
                        ),
                    },
                    {
                      path: 'device',
                      component: () =>
                        import(
                          /* webpackChunkName: "main-profile" */ './views/main/device/DeviceHardwareShow.vue'
                        ),
                    },
                    {
                      path: 'company',
                      component: () =>
                        import(
                          /* webpackChunkName: "main-profile" */ './views/main/enterprise/EnterpriseMangerShow.vue'
                        ),
                    },
                    {
                      path: 'log',
                      component: () =>
                        import(
                          /* webpackChunkName: "main-profile" */ './views/main/log/LogListShow.vue'
                        ),
                    },
                    {
                      path: 'enterprise',
                      component: () =>
                        import(
                          /* webpackChunkName: "main-profile" */ './views/main/enterprise/EnterpriseInfo.vue'
                        ),
                    }
                  ],
                },
                {
                  path: 'statistics',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/bottom_guild/Statistics.vue'
                    ),
                  children: [
                    {
                      path: 'date',
                      name: 'date',
                      component: () =>
                        import(
                          /* webpackChunkName: "main-profile" */ './views/main/bottom_guild/statistics/Date.vue'
                        ),
                    }
                  ],
                },
                {
                  path: 'summary',
                  name: 'summary',
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ './views/main/bottom_guild/Summary.vue'
                    ),
                }
              ],
            },
            {
              path: 'hardware',
              component: RouterComponent,
              redirect: 'hardware/view',
              children: [
                {
                  path: 'view',
                  component: () =>
                    import(
                      /* webpackChunkName: "device-hardware-show" */ './views/main/device/DeviceHardwareShow.vue'
                    ),
                }
              ],
            },
            {
              path: 'enterprise',
              component: RouterComponent,
              redirect: 'enterprise/view',
              children: [
                {
                  path: 'view',
                  component: () =>
                    import(
                      /* webpackChunkName: "device-hardware-show" */ './views/main/enterprise/EnterpriseMangerShow.vue'
                    ),
                }
              ],
            }
          ],
        }
      ],
    },
    {
      path: '/*',
      redirect: '/',
    }
  ],
})
